//轉換px To Rem
@function tR($value) {
    $remValue: calc($value / 16) + rem;
    @return $remValue;
}

//增加input的placeholder樣式
@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}
}
@include placeholder {
    font-style: normal;
    font-size: tR(14);
    letter-spacing: 0.5px;
    font-weight: 400;
}

//背景設定mixin
@mixin bgCover{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

//間距
$max: 20;
@for $i from 0 through $max {
    .gb#{$i*5} {
        bottom: $i * 5px !important;
        margin-bottom: - ($i * 5px) !important;
    }
    .gt#{$i*5} {
        top: $i * 5px !important;
        margin-top: - ($i * 5px) !important;
    }
    .mb#{$i*5} {
        margin-bottom: $i * 5px !important;
    }
    .mt#{$i*5} {
        margin-top: $i * 5px !important;
    }
    .me#{$i*5} {
        margin-right: $i * 5px !important;
    }
    .ms#{$i*5} {
        margin-left: $i * 5px !important;
    }
    .pb#{$i*5} {
        padding-bottom: $i * 5px !important;
    }
    .pt#{$i*5} {
        padding-top: $i * 5px !important;
    }
    .pe#{$i*5} {
        padding-right: $i * 5px !important;
    }
    .ps#{$i*5} {
        padding-left: $i * 5px !important;
    }
}
@for $i from 0 through $max {
    @media(max-width: 567.98px) {
        .gb#{$i*5}-md {
            bottom: $i * 5px !important;
            margin-bottom: - ($i * 5px) !important;
        }
        .gt#{$i*5}-md {
            top: $i * 5px !important;
            margin-top: - ($i * 5px) !important;
        }
        .mb#{$i*5}-md {
            margin-bottom: $i * 5px !important;
        }
        .mt#{$i*5}-md {
            margin-top: $i * 5px !important;
        }
        .me#{$i*5}-md {
            margin-right: $i * 5px !important;
        }
        .ms#{$i*5}-md {
            margin-left: $i * 5px !important;
        }
        .pb#{$i*5}-md {
            padding-bottom: $i * 5px !important;
        }
        .pt#{$i*5}-md {
            padding-top: $i * 5px !important;
        }
        .pe#{$i*5}-md {
            padding-right: $i * 5px !important;
        }
        .ps#{$i*5}-md {
            padding-left: $i * 5px !important;
        }
    }
}

//透明度
$opacityMax: 10;
@for $i from 1 through $opacityMax {
    .opacity#{$i*10} {
        opacity: $i * 10 !important;
    }
}

// retina images
$retina: "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)";

@mixin retina($file, $type) {
    background-image: url($file + '.' + $type);
    @media #{$retina} {
        background-image: url($file + '@2x.' + $type);
    }
}
