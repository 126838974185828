.swiper-button-next, .swiper-button-prev{
    color: #fff;
    &:after{
        font-size: 30px;
    }
}
.swiper-pagination-bullet{
    background: $white;
    &-active{
        background: $red;
    }
}
