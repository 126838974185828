//加入自定義字體
@font-face {
    font-family: 'Engravers MT';
    src: url('../fonts/EngraversMT.woff2') format('woff2'),
    url('../fonts/EngraversMT.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lao MN';
    src: url('../fonts/LaoMN.woff2') format('woff2'),
    url('../fonts/LaoMN.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lao MN';
    src: url('../fonts/LaoMN-Bold.woff2') format('woff2'),
    url('../fonts/LaoMN-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Skia';
    src: url('../fonts/Skia-Regular.woff2') format('woff2'),
    url('../fonts/Skia-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


//Google font
@import url('https://fonts.googleapis.com/css2?family=Mukta+Mahee:wght@200;300;400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
