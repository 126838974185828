body{
    font-family: 'Noto Sans TC', sans-serif;
    background: $brown;
    color: $white;
    padding-top: 70px;
    &.bg-pink{
        .titleBox--line{
            background: $black2;
        }
        .sideFixedNav--name, .sideFixedNav--number{
            color: $black;
        }
        .sideFixedNav--number:after{
            background: $black;
        }
    }
    &:not(.body-production){
        padding-top: 94px;
        .mainNav{
            top: 24px;
        }
    }
}
.content{
    min-height: calc(100vh - 230px);
}

//主選單
.mainNav{
    background: $pink;
    padding: 17px 100px;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 20;
    .navToggle{
        font-size: tR(14);
        font-family: 'Mukta Mahee', sans-serif;;
        color: $brown;
        font-weight: 500;
        letter-spacing : 2.64px;
        transition: all .5s;
        cursor: pointer;
        &:hover{
            color: $red;
        }
    }
    .navLogo{
        img{
            transition: all .5s;
        }
        &:hover{
            img{
                opacity: .8;
            }
        }
    }
}

//側邊頁面資訊
.sideFixedNav{
    position: fixed;
    right: 0px;
    top: 40%;
    text-align: right;
    z-index: 999;
    span{
        display: none;
    }
    &--name{
        margin-right: 17px;
        margin-left: auto;
        font-family: 'Mukta Mahee', sans-serif;
        font-weight : bold;
        font-size : 12px;
        letter-spacing : 2.64px;
        color : $gray;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    &--number{
        font-family: 'Mukta Mahee', sans-serif;
        font-weight : bold;
        font-size : 12px;
        letter-spacing : 2.64px;
        color : $gray;
        margin: 12px 0 15px 0;
        &:after{
            content: '';
            width: 32px;
            height: 1px;
            background: $gray;
            display: inline-block;
            margin-left: 5px;
            position: relative;
            top: -3px;
        }
    }
}

//首頁
.homeSwiper{
    margin: 100px 0 200px 0;
    &.aboutSwiper{
        margin-bottom: 100px;
    }
    .swiper-slide{
        padding-top: 60%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    &--content{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        display: flex;
        padding: 60px;
        flex-direction: column;
    }
    &--title{
        width: 380px;
        font-size : tR(20);
        line-height : tR(30);
        letter-spacing : 3.36px;
        color : $white;
        margin-bottom: 20px;
    }
    &--desc{
        width: 380px;
        font-size : tR(14);
        line-height : tR(23);
        letter-spacing : 0.84px;
        color : $white;
    }
}

.homeNews{
    margin-bottom: 200px;
    &--list{
        margin-bottom: 40px;
    }
}

//footer
.footer{
    height: 160px;
    width: 100%;
    z-index: 20;
    background: $black;
    padding: 35px 0 15px 0;
    &--logo{
        position: relative;
        top: -13px;
    }
    &--logoImg{
        width: 120px;
    }
    &--link{
        color: $gray;
        text-decoration: none;
        font-weight: 700;
        &:hover{
            color: $red;
        }
    }
    &--line{
        border-bottom: 1px solid $gray;
    }
    &--info{
        margin-top: 10px;
        p{
            color: $gray;
            font-size: tR(13);
            margin-bottom: 5px;
            letter-spacing: 1px;
            small{
                transform-origin: top left;
                display: block;
                font-size: tR(12);
                transform: scale(.9);
            }
            a{
                color: $gray;
            }
        }
    }
}

//主選單彈窗
#menuModal{
    .modal-close{
        color: $red;
        font-size: tR(14);
        font-family: 'Mukta Mahee', sans-serif !important;
        letter-spacing: 3px;
        font-weight: 700;
        text-decoration: none;
        position: absolute;
        top: 55px;
        right: 95px;
        &:hover i{
            transform: rotate(360deg);
        }
        i{
            transform: rotate(0deg);
            transition: transform 1s;
            font-size: tR(22);
            position: relative;
            top: tR(3);
        }
    }
    .modal-dialog{
        max-width: 100%;
        width: 100%;
        margin: 0;
        height: 100vh;
    }
    .modal-content{
        height: 100%;
        background: $brown;
        border: none;
        border-radius: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.menuModal{
    &--title{
        font-size: tR(38);
        color: $red;
        font-family: 'Lao MN';
        font-weight: 300;
        margin-bottom: 3px;
    }
    &--link{
        color: $gray;
        text-decoration: none;
        &:hover{
            color: $red;
        }
    }
    &--menu{
        width: 420px;
        margin: 30px auto;
        border-top: 1px solid $gray;
        border-bottom: 1px solid $gray;
        padding: 55px 0;
        display: flex;
        flex-direction: row;
        ul{
            padding: 0;
            margin: 0;
            li{
                list-style: none;
                font-size : tR(14);
                line-height : tR(30);
                letter-spacing : 3.08px;
                a{
                    color: $gray;
                    text-decoration: none;
                    &:hover{
                        color: $red;
                    }
                    span{
                        font-size: tR(12);
                        font-family: 'Mukta Mahee', sans-serif !important;
                    }
                }
            }
        }
    }
    &--menu1{
        flex: 0 0 auto;
    }
    &--menu2{
        flex: 1;
        li{
            position: relative;
            span{
                opacity: 1;
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                transition: opacity .5s;
            }
            i{
                font-style: normal;
                opacity: 0;
                transition: opacity .5s;
            }
            &:hover{
                span{
                    opacity: 0;
                }
                i{
                    opacity: 1;
                }
            }
        }
    }
    &--line{
        width: 60px;
        display: inline-block;
        height: 1px;
        background: $gray;
        margin: 0 20px;
        position: relative;
        top: -5px;
    }
    &--footer{
        width: 420px;
        margin: 0 auto;
    }
}

//關於
.aboutNavButton{
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    &--cover{
        display: block;
        padding-top: 66%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
    }
    &--coverSquare{
        display: block;
        padding-top: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
    }

    img{
        opacity: .8;
        transform-origin: center;
        transition: all .5s;
        border: 1px solid $white;
        transform: scale(1);
        z-index: 1;
        position: relative;
        width: 100%;
    }
    &:hover img{
        transform: scale(1.1);
        opacity: 1;
        border: 1px solid $black;
    }
    &--desc{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 3;
        font-size: tR(16);
        line-height : 27.04px;
        letter-spacing : 1.92px;
        color : $gray;
        text-align: center;
    }
    &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        transition: all .5s;
        background: rgba($brown,.6);
        z-index: 2;
    }
    &:hover:after{
        background: rgba($brown,.1);
    }
}
.aboutVision{
    background-position: center;
    background-size: 100%;
    position: relative;
    padding-top: 66.75%;
    .aboutVision--text{
        width: 420px;
        font-size : tR(20);
        line-height : tR(30);
        letter-spacing : 3.36px;
        color : $white;
        position: absolute;
        left: 50px;
        bottom: 50px;
    }
}

//最新消息
.newsIndex, .projectIndex, .projectDetail, .projectProgress, .newsDetail{
    padding: 100px 0;
    .titleBox{
        margin-bottom: 50px;
    }
}
.newsIndex{
    .newsItem{
        padding: 0 15px;
    }
}
.newsDetail{
    &--link{
        font-size: 12px;
        color: $black;
        letter-spacing: 2px;
    }
    img{
        width: 100%;
        height: auto;
    }
    .editorContent{
        p{
            color: $black2;
        }
    }
}
.projectDetail{
    .titleBox{
        margin-bottom: 120px;
    }
    .descBox{
        margin-bottom: 130px;
        p{
            font-size: tR(16);
            line-height: tR(24);
            letter-spacing: 2px;
        }
    }
    &--img{
        width: 130px;
        margin-bottom: 15px;
    }
    &--label{
        font-size: tR(12);
        letter-spacing: 1px;
        width: 130px;
        padding: 2px;
        text-align: center;
        border: 1px solid $gray;
        margin-bottom: 30px;
    }
    &--title{
        font-size: tR(16);
        letter-spacing: 1px;
        margin-bottom: 5px;
        color: $gray;
    }
    &--website{
        font-size: tR(14);
        letter-spacing: 1px;
        color: $gray;
    }
    &--phone{
        font-size: tR(14);
        letter-spacing: 1px;
        color: $gray;
    }
    &--desc{
        font-size: tR(12);
        line-height: 1.8;
        letter-spacing: 1px;
        margin-top: 25px;
        margin-bottom: 25px;
        color: $gray;
    }
}

//專案列表
.projectItemList{
    &:hover .projectDesc{
        opacity: 1;
    }
    &:nth-child(odd){
        //justify-content: flex-start;
        .projectItem{
            order: 0;
            &:after{
                content: '';
                height: 100%;
                width: 3px;
                position: absolute;
                right: -1.5px;
                top: 0px;
                background: $red;
            }
        }
        .projectDesc{
            order: 1;
        }
        .projectItem--text{
            margin-right: 30px;
        }
        .projectItem--desc{
            flex-direction: row-reverse;
        }
    }
    &:nth-child(even){
        //justify-content: flex-end;
        .projectItem{
            order: 1;
            &:after{
                content: '';
                height: 100%;
                width: 3px;
                position: absolute;
                left: -1.5px;
                top: 0px;
                background: $red;
            }
        }
        .projectDesc{
            order: 0;
        }
        .projectItem--line{
            margin-right: 30px;
        }
    }
}
.projectDesc{
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all .5s;
    &--link{
        margin-top: 30px;
        text-align: right;
        a{
            font-size: tR(12);
            color: $white;
        }
    }
    &--wrapper{
        width: 80%;
        margin-bottom: 100px;
    }
    &--title{
        margin-bottom: 60px;
        h2{
            font-size: tR(22);
            letter-spacing : 2.88px;
            color : $white;
        }
        h3{
            font-family : 'Lao MN';
            font-size: tR(12);
            letter-spacing : 0.72px;
            color : $white;
        }
    }
    &--slogan{
        margin-bottom: 40px;
        font-size: tR(24);
        letter-spacing : 2.88px;
        color : $white;
    }
    &--content{
        font-size: tR(12);
        letter-spacing : 0.72px;
        color : $white;
        span{
            display: inline-block;
            margin-right: 15px;
            font-weight: 700;
        }
    }
    &--contentItem{
        flex: 0 0 100%;
        max-width: 100%;
    }
    &--contentWrapper{
        flex-wrap: wrap;
    }
}
.projectItem{
    position: relative;
    padding: 0 35px 35px 35px;
    &--img{
        width: 100%;
        display: block;
        padding-top: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    &--link{
        text-decoration: none;
    }
    &--desc{
        display: flex;
        flex-direction: row;
        margin-top: 30px;
    }
    &--text{
        flex: 0 0 auto;
        h3{
            font-family: 'Engravers MT';
            font-size : tR(50);
            line-height : tR(50);
            letter-spacing : 5.04px;
            color : $red;
            opacity : 0.87;
            margin-bottom: 10px;
        }
        p{
            font-size: tR(14);
            color: $white;
        }
    }
    &--line{
        flex: 1;
        height: 1px;
        background: $red;
        width: 100%;
        max-width: 100%;
        position: relative;
        top: 15px;
    }
}

.descBox{
    &--title{
        font-size : tR(16);
        line-height : tR(24);
        letter-spacing : 1.92px;
        color : $gray;
        margin-bottom: 50px;
    }
    &--decoline{
        width: 22px;
        height: 3px;
        background: $red;
        margin: 0 auto 26px auto;
    }
}

.progressBar{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $red;
    margin-bottom: 30px;
    flex-wrap: wrap;
    &--title{
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 10px;
        font-size: tR(16);
        letter-spacing: 2px;
        color: $white;
    }
    &--active{
        border-bottom: 10px solid $red;
    }
    &--bg{
        flex: 1;
        //background-image: url('../images/progressBG.jpg');
        background-position: left center;
        background-size: 1200px 18px;
    }
    &--text{
        margin-left: 10px;
        flex: 0 0 30px;
        max-width: 30px;
        color: $red;
        font-family: 'Mukta Mahee', sans-serif;;
        font-weight : bold;
        font-size : tR(16);
        letter-spacing : 2.64px;
        text-align: right;
    }
}

.progressBars{
    margin-bottom: 200px;
}
.progressIcon{
    text-align: center;
    background: #524d4e;
    border: 1px solid $gray;
    padding: 20px 0;
    img{
        width: 50px;
        margin-bottom: 5px;
    }
    div{
        font-size : tR(12);
        line-height : tR(18);
        letter-spacing : 1.44px;
        color : $gray;
    }
}
.progressList{
    text-align: center;
    margin: 30px 0 0 0px;
    padding: 0px;
    li{
        font-size : tR(12);
        line-height : tR(18);
        letter-spacing : 1.44px;
        margin-bottom: 8px;
        color : $gray;
        list-style: none;
        a{
            color : $gray;
            text-decoration: underline;
        }
    }
}

.progressItem{
    text-align: center;
    margin-bottom: 50px;
    &--bg{
        padding-top: 66%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    img{
        margin-bottom: 10px;
        height: 250px;
        width: 100%;
        object-fit: cover;
    }
    a{
        text-decoration: none;
    }
    p.para{
        color: $black2;
    }
}

#progressModal {
    .modal-close {
        color: $red;
        font-size: tR(14);
        font-family: 'Mukta Mahee', sans-serif !important;
        letter-spacing: 3px;
        font-weight: 700;
        text-decoration: none;
        position: absolute;
        top: 55px;
        right: 95px;
        z-index: 1000;
        &:hover i {
            transform: rotate(360deg);
        }
        i {
            transform: rotate(0deg);
            transition: transform 1s;
            font-size: tR(22);
            position: relative;
            top: tR(3);
        }
    }
    .modal-dialog{
        max-width: 100%;
        width: 100%;
        margin: 0;
        height: 100vh;
    }
    .modal-content{
        height: 100%;
        background: rgba($black,.8);
        border: none;
        border-radius: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .modal-body{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            max-height: 80%;
            max-width: 85%;
            margin-bottom: 10px;
        }
    }
}

//聯絡我們
.contactInfo{
    margin-bottom: 30px;
    &--item{
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
    }
    &--title{
        flex: 0 0 70px;
        max-width: 70px;
        font-family: 'Mukta Mahee', sans-serif;
        font-weight : bold;
        font-size : tR(12);
        line-height : tR(24);
        letter-spacing : 2.64px;
        color : $black2;
        margin-right: 10px;
    }
    &--content{
        flex: 1;
        font-size : tR(12);
        line-height : tR(24);
        letter-spacing : 1.24px;
        color : $black2;
        margin-right: 10px;
    }
}

.contactForm{
    border: 1px solid rgba($black2,0.25);
    padding: 35px;
    &--item{
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        width: 100%;
    }
    &--label{
        font-family: 'Mukta Mahee', sans-serif;
        font-weight : bold;
        font-size : tR(12);
        line-height : tR(24);
        letter-spacing : 2.64px;
        flex: 0 0 auto;
        max-width: initial;
        margin-right: 15px;
        color: $black2;
    }
    &--input {
        background: none;
        flex: 1;
        font-size: tR(14);
        padding-left: 15px;
        border: none;
        border-bottom: 1px solid rgba($black2,0.25);
    }
    #send-form, .btn{
        font-family: 'Mukta Mahee', sans-serif;
    }
}
.contact-page .content{
    display: flex;
    align-items: center;
    justify-content: center;
}
.contactUs{
    .phoneLink{
        color: #333;
    }
    &--para{
        letter-spacing: .5px;
        font-size: tR(14);
        line-height : tR(24);
        color: $black;
    }
    &--content{
        //margin-top: 130px;
        //margin-bottom: 130px;
    }
    .para, .footer--link{
        color: $black2;
    }
    hr{
        border-color: $black2;
    }
}

//首頁熱銷建案
.homeCases{
    margin-bottom: 150px;
    a{
        text-decoration: none;
        color: #fff;
    }
    &--info{
        background: $black;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    &--phone{
        font-size: tR(24);
        color: $red;
        margin-top: 30px;
        a{
            color: $red;
        }
    }
    &--label{
        position: absolute;
        top: 0px;
        left: 0px;
        padding-top: 32px;
        padding-left: 32px;
        font-size: tR(12);
        color: $red;
        &:after{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 36px 36px 0 0;
            border-color: $red transparent transparent transparent;
            position: absolute;
            top: 0px;
            left: 0px;
        }
    }
    &--title{
        text-align: center;
        h2{
            font-size : tR(24);
            line-height : tR(24);
            letter-spacing : 2.88px;
            margin-bottom: 50px;
        }
        h3{
            font-family : 'Lao MN';
            font-size : tR(12);
            letter-spacing : 0.72px;
        }
        h4{
            font-size : tR(12);
            letter-spacing : 0.72px;
        }
    }
    &--img{
        position: relative;
        padding-top: 66.66%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        &:after {
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            position: absolute;
            top: 5px;
            left: 5px;
            content: '';
            z-index: 10;
        }
    }
}

//報修
.repairForm{
    margin-top: 20px;
    &--content{
        background: #4F4D4D;
    }
    .select-box{
        border-bottom: 1px solid $brown;
        select{
            color: $white;
            padding-left: 20px;
            height: 46px;
            option{
                color: $white;
            }
        }
        i{
            color: $brown;
        }
    }
    input.form-control{
        background: none;
        padding-left: 20px;
        border: none;
        font-size: tR(14);
        color: $white;
        height: 46px;
        border-bottom: 1px solid $brown;
        border-radius: 0px;
        &::placeholder {
            color: $white;
            opacity: 1;
        }
        &:-ms-input-placeholder {
            color: $white;
        }
        &::-ms-input-placeholder {
            color: $white;
        }
    }
    textarea.form-control{
        background: none;
        padding-left: 20px;
        border: none;
        font-size: tR(14);
        color: $white;
        height: 250px;
        border-bottom: 1px solid $brown;
        padding-top: 15px;
        &::placeholder {
            color: $white;
            opacity: 1;
        }
        &:-ms-input-placeholder {
            color: $white;
        }
        &::-ms-input-placeholder {
            color: $white;
        }
    }
}

//相簿元件
.progressAlbum{
    margin-bottom: 25px;
    border: 1px solid $pink;
    a{
        color: $white;
        text-decoration: none;
        transition: all .2s;
    }
    &--cover{
        padding-top: 66.66%;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-bottom: 1px solid $pink;
        >a{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: 10;
        }
    }
    &--desc{
        display: flex;
        align-items: center;
        padding: 16px 15px;
        transition: all .2s;
    }
    &--title{
        flex: 1;
        font-size: tR(16);
        line-height: tR(20);
        letter-spacing: 1.5px;
    }
    &--time{
        flex: 0 0 45px;
        max-width: 45px;
        text-align: center;
        .month{
            font-family: 'Mukta Mahee', sans-serif;
            font-size : tR(40);
            margin-bottom: 0px;
            line-height: tR(40);
        }
        .year{
            font-family: 'Mukta Mahee', sans-serif;
            font-size: tR(12);
        }
    }
    &:hover{
        .progressAlbum--desc{
            background: $pink;
        }
        a{
            color: $black;
        }
    }
}

//工程進度
.progressProjectList{
    padding-bottom: 100px;
}

.phoneLink{
    text-decoration: underline;
}


.dev-alert{
    position: fixed;
    height: 24px;
    background: rgba(red,.4);
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 24px;
    top: 0px;
    left: 0px;
    z-index: 99999;
    width: 100%;
    letter-spacing: 2px;
}

.flipbook-wrapper{
    padding-top: min(60%, 80vh);
    position: relative;
    background-color: #fafafa;
    margin-bottom: 25px;
    .flipbook{
        left: 0;
        top: 0;
        position: absolute;
        height: 100%;
        width: 100%;
    }
}

#alertModal{
    .modal-content{
        border-radius: 0px;
    }
    .btn-close{
        position: absolute;
        top: -30px;
        right: 0px;
        background: none;
        opacity: 1;
        i{
            color: #fff;
            font-size: 24px;
        }
    }
    .modal-body{
        padding: 40px;
        h3{
            text-align: center;
            color: #a92d2d;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        p{
            color: #a92d2d;
            font-size: 14px;
            letter-spacing: 1px;
        }
    }
}
