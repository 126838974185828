@media(max-width: 767.98px){
    body{
        padding-bottom: 0px;
        padding-top: 50px;
    }

    .container{
        padding-left: 25px;
        padding-right: 25px;
    }

    .btn.btn-black{
        padding: 7px 13px;
    }

    .mainNav{
        padding: 8px 10px;
        //border-bottom: 1px solid $black;
        &--logo2{
            width: 60px;
        }
    }

    .sideFixedNav{
        display: none;
    }

    .swiper-button-next:after, .swiper-button-prev:after{
        font-size: 24px;
    }

    .homeNews{
        margin-bottom: 70px;
        .newsItem{
            background: none;
            &--desc{
                padding-left: 0px;
                padding-right: 0px;
            }
            &--titleTime{
                display: block;
                font-family: 'Mukta Mahee', sans-serif;
                flex: 0 0 auto;
                margin-right: 15px;
            }
            &--dates{
                display: none;
            }
            &--title{
                color: #fff;
                display: flex;
                font-size: tR(14);
            }
            &--titleContent{
                flex: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                a{
                    color: #fff;
                }
            }
            &--content{
                display: none;
            }
            &--link{
                color: #fff;
                float: right;
            }
            &--img{
                padding-top: 35%;
            }
        }
    }

    .homeSwiper{
        margin: 30px 0 100px 0;
        &.aboutSwiper{
            margin-bottom: 50px;
        }
        &--content{
            margin-top: 15px;
            padding: 0px 20px 0px 20px;
            position: relative;
            top: initial;
            left: initial;
            border-left: 3px solid $red;
        }
        &--title{
            width: 100%;
            margin-bottom: 15px;
            font-weight: 700;
            p{
                margin-bottom: 0px;
                font-weight: 700;
            }
        }
        &--desc{
            width: 100%;
            margin-bottom: 0px;
            p:last-child{
                margin-bottom: 0px;
            }
        }
        .swiper-slide{
            padding-top: 70%;
            background-size: 100%;
            background-position: top center;
        }
    }

    .para{
        font-size: tR(12);
        line-height: tR(22);
    }

    .titleBox{
        h3{
            font-size: tR(24);
        }
        &__normalMargin{
            margin: 30px 0;
        }
        &--line{
            height: 24px;
        }
        &--desc{
            font-size: tR(14);
            line-height: tR(24);
            width: 98%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .newsItem{
        margin-bottom: 15px;
        &--title{
            font-size: tR(16);
            line-height: tR(20);
        }
        &--dates{
            padding: 5px 0;
        }
        &--my{
            display: inline-block;
            text-transform: uppercase;
        }
        &--date{
            display: inline-block;
            font-size: tR(26);
            line-height: tR(26);
        }
        &--img{
            margin-bottom: 15px;
        }
        &--desc{
            padding-bottom: 20px;
        }
    }

    .footer{
        padding: 25px 0 15px 0;
        height: auto;
        color: #fff;
        &--link{
            color: #fff;
        }
        &--topRow{
            margin: 0 0px 8px 0;
            border-bottom: 1px solid #fff;
            padding-bottom: 5px;
        }
        &--line{
            display: none;
        }
        &--logo{
            top: -5px;
            img{
                width: 80px;
            }
        }
        &--info{
            margin: 5px 0 0 0;
            transform-origin: center;
            text-align: center;
            p{
                text-align: center;
                font-size: tR(12);
                color: #fff;
                small{
                    transform: scale(1);
                }
                &:first-child{
                    text-align: left;
                    font-size: tR(12);
                    letter-spacing: 1px;
                    margin-bottom: 30px;
                    line-height: 1.8;
                }
                &:last-child{
                    transform: scale(.8);
                    margin-left: -10%;
                    margin-right: -10%;
                }
            }
        }
    }

    .homeCases{
        margin-bottom: 80px;
        &--label{
            padding-top: 3px;
            padding-left: 5px;
            span{
                opacity: 1;
                color: $black;
                position: relative;
                z-index: 2;
                transform: scale(.8);
                transform-origin: top left;
                display: block;
            }
            &:after{
                opacity: 1;
                border-width: 56px 56px 0 0;
            }
        }
        &--info{
            height: 100%;
            padding: 35px 0 30px 0;
        }
        &--title{
            h2{
                font-size: tR(16);
                line-height: tR(16);
                margin-bottom: 15px;
            }
            h3{
                margin-bottom: 35px;
            }
            h4{
                font-size: tR(12);
                line-height: tR(12);
            }
        }
        &--img{
            height: 100%;
        }
    }

    .to-top{
        bottom: 120px;
    }

    .menuModal {
        &--title{
            font-size: tR(28);
        }
        &--menu2 {
            li {
                span{
                    opacity: 0;
                }
                i{
                    opacity: 1;
                }
            }
        }
    }

    .menuModal{
        &--link{
            margin-right: 5px !important;
            margin-left: 5px !important;
        }
        &--footer{
            width: 70%;
            img{
                width: 70px;
                margin-left: 15px;
            }
        }
        &--menu{
            width: 70%;
        }
        &--line{
            width: 42px;
            margin: 0 10px 0 5px;
        }
    }
    #menuModal, #progressModal{
        .modal-close{
            top: 20px;
            right: 30px;
            font-size: tR(12);
            i{
                font-size: tR(16);
            }
        }
    }

    .about{
        &--img{
            width: 100%;
        }
    }

    .aboutNavButton{
        border: none;
        &:after{
            background: rgba(0,0,0,0.6);
        }
        &--desc{
            font-size: tR(14);
            line-height: tR(20);
        }
    }

    .aboutVision{
        margin-top: 0px !important;
        margin-left: -25px;
        margin-right: -25px;
        &--text{
            font-size: tR(14);
            line-height: tR(24);
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
            padding: 0 30px;
            bottom: initial;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .aboutVisionText{
        font-size: tR(14);
        line-height: tR(28);
        letter-spacing: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 35px;
        color: #c6c6c6;
        width: 100%;
        margin: 0 auto 40px auto;
        position: relative;
        text-align: center;
    }
    .aboutVisionText:before, .aboutVisionText:after, .aboutVisionText>:first-child:before, .aboutVisionText>:first-child:after {
        position:absolute;
        width: 30px;
        height: 30px;
        border-color: #c6c6c6; /* or whatever colour */
        border-style: solid; /* or whatever style */
        content: ' ';
    }
    .aboutVisionText:before {top:0;left:0;border-width: 1px 0 0 1px}
    .aboutVisionText:after {top:0;right:0;border-width: 1px 1px 0 0}
    .aboutVisionText>:first-child:before {bottom:0;right:0;border-width: 0 1px 1px 0}
    .aboutVisionText>:first-child:after {bottom:0;left:0;border-width: 0 0 1px 1px}

    .bgTitle{
        .bgTitle{
            &--text{
                font-size: tR(14);
                margin-bottom: 10px;
            }
            &--text2{
                font-size: tR(14);
                letter-spacing: 2px;
            }
        }
    }

    .newsIndex, .projectIndex, .projectDetail, .projectProgress, .newsDetail, .contactUs{
        padding: 50px 0;
    }
    .projectIndex .container{
        padding-left: 30px;
        padding-right: 30px;
    }

    .title2{
        font-size: tR(18);
        line-height: tR(26);
    }

    .projectDesc{
        //display: none;
        opacity: 1;
        &--wrapper {
            width: 100%;
        }
        &--title {
            margin-bottom: 10px;
            font-size: tR(14);
            display: none;
        }
        &--slogan{
            font-size: tR(14);
            margin-bottom: 8px;
        }
        &--link{
            margin-top: 5px;
        }
        &--content{
            margin-bottom: 15px;
        }
        &--contentItem{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .projectItemList{
        .projectItem{
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 10px;
            &--text{
                text-align: center;
                h3{
                    font-size: tR(24);
                    line-height: tR(24);
                    letter-spacing: 1px;
                    position: relative;
                    margin-bottom: 15px;
                    &:after{
                        content:'';
                        width: calc(100% - 40px);
                        top: 10px;
                        position: absolute;
                        border-bottom: 1px solid $red;
                    }
                }
                p{
                    font-size: tR(14);
                    line-height: tR(14);
                    padding-left: 50px;
                    letter-spacing: 2.88px;
                    position: relative;
                    &:after{
                        content: '';
                        width: 40px;
                        height: 1px;
                        position: absolute;
                        top: 6px;
                        left: 0px;
                        background: $red;
                    }
                }
            }
            &--line{
                display: none;
            }
            &:after{
                display: none;
            }
            &--link{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                >img{
                    flex: 0 0 90%;
                    max-width: 90%;
                    height: auto;
                    object-fit: cover;
                    order: 0;
                }
            }
            &--desc{
                flex: 0 0 100%;
                justify-content: center;
                order: 1;
                margin-top: 18px;
            }
            &--text{
                flex: 0 0 100%;
                p{
                    padding-left: 0px;
                    &:after{
                        display: none;
                    }
                }
            }
        }
        &:nth-child(odd){
            .projectItem--text {
                h3, p{
                    text-align: right;
                }
                h3:after{
                    left: -30px;
                }
            }
            .projectItem--link{

            }
        }
        &:nth-child(even){
            .projectItem--text {
                h3, p{
                    text-align: left;
                }
                h3:after{
                    right: -30px;
                }
            }
            .projectItem--link{
                img{
                    margin-left: auto;
                }
            }
            .projectItem--desc{
            }
            .projectItem{
                order: 0;
            }
        }
    }

    .projectDetail {
        .descBox {
            margin-bottom: 80px;
            p {
                font-size: tR(14);
                line-height: tR(20);
                margin-bottom: 5px;
            }
        }
        &--textBox{
            padding: 0 15px;
        }
        &--imgBox{
            padding: 0 15px;
        }
    }

    .contactUs{
        padding-left: 10px;
        padding-right: 10px;
        &--logo{
            display: none;
        }
        &--content{
            margin-bottom: 50px;
        }
    }
    .contactInfo{
        &--title{
            line-height: tR(22);
            flex: 0 0 100px;
            max-width: 100px;
        }
        &--content{
            line-height: tR(22);
        }
    }
    .contactLinks{
        margin-bottom: 40px;
        text-align: right;
    }

    .progressBars{
        padding: 0 15px;
        margin-bottom: 100px;
    }
    .progressBar{
        margin-bottom: 15px;
        &--title{
            font-size: tR(13);
        }
    }
    .progressItem{
        margin-bottom: 15px;
        img{
            height: 130px;
        }
    }

    .progressAlbum{
        &--title{
            font-size: tR(14);
        }
        &--time{
            .month{
                font-size: tR(20);
                line-height: tR(20);;
            }
        }
    }

    #progressModal{
        .modal-body{
            overflow-y: auto;
            img{
                max-width: 250%;
                max-height: 100%;
            }
        }
    }

    .progress--desc{
        width: 90% !important;
    }

    .repair--img{
        width: 100%;
    }

    .progressProjectList{
        padding-top: 30px;
        .row{
            margin-right: -10px;
            margin-left: -10px;
        }
        .col-6{
            padding-left: 5px;
            padding-right: 5px;
            margin-top: 0px;
        }
        .progressProjectItem a{
            margin-bottom: 10px;
        }
    }

    .to-top{
        bottom: 60px;
        right: -10px;
    }
}
