a{
    transition: all .5s;
}


//字體
.font-mukta{
    font-family: 'Mukta Mahee', sans-serif !important;
}

//背景顏色
.bg-pink{
    background: $pink !important;
}
.bg-brown{
    background: $brown !important;
}
.bg-black{
    background: $black !important;
}
.bg-red{
    background: $red !important;
}

//標題元件
.titleBox{
    margin-bottom: 35px;
    text-align: center;
    &__normalMargin{
        margin: 80px 0;
    }
    h3{
        font-size: tR(38);
        color: $red;
        font-family: 'Lao MN';
        font-weight: 400;
        margin-bottom: 3px;
    }
    h4{
        font-size: tR(16);
        line-height: tR(21);
        letter-spacing : 1.92px;
        color : $gray;
    }
    &--line{
        width: 1px;
        height: 50px;
        background: #fff;
        margin: 5px auto 10px auto;
        display: block;
    }
    &--desc{
        font-size: tR(14);
        line-height: tR(24);
        letter-spacing : 0.84px;
        color : $gray;
    }
    &__black{
        .titleBox--desc, h4{
            color: $black2;
        }
    }
}

//按鈕
.btn.btn-black{
    background: $black2;
    color: $gray;
    padding: 13px;
    border-radius: 0px;
    font-size: tR(12);
    &:hover{
        background: $gray;
        color: $black2;
    }
}
.btn-long{
    min-width: 150px;
}

.title2{
    font-size: tR(24);
    line-height: tR(32);
    font-weight: 700;
    letter-spacing : 2.88px;
    color: $black2;
}
.title3{
    font-size: tR(16);
    line-height: tR(27);
    font-weight: 700;
    letter-spacing : 1.68px;
    color: $gray;
}
.para{
    font-size: tR(14);
    line-height : tR(24);
    letter-spacing : 1.68px;
    color: $gray;
}
.line{
    border-bottom: 1px solid $gray;
}
.bgTitle{
    flex-direction: row;
    .bgTitle--text{
        flex: 0 0 auto;
        color: $brown;
        background: $yellow;
        padding: 5px 10px;
        font-weight: 700;
        letter-spacing: 2px;
        font-size: tR(16);
        margin-bottom: 35px;
        margin-right: 15px;
    }
    .bgTitle--text2{
        flex: 0 0 auto;
        color: $gray;
        padding: 5px 10px;
        font-weight: 700;
        letter-spacing: 4px;
        font-size: tR(16);
        margin-right: 15px;
    }
    .bgTitle--line{
        flex: 1;
        height: 1px;
        background: $gray;
        width: 100%;
        max-width: 100%;
        position: relative;
        top: 15px;
    }
}

//左右線匡的區塊
.lineBox{
    border: 1px solid $gray;
    height: 100%;
    width: 100%;
    &__noRight{
        border-right: none;
    }
    &__noLeft{
        border-left: none;
    }
}

//最新消息
.newsItem{
    background: $pink;
    border-bottom: $brown 1px solid;
    transition: all .5s;
    &:hover{
        background: #f0e6df;
        .newsItem--img{
            filter: grayscale(0);
            -webkit-filter: grayscale(0);
        }
    }
    &--titleTime{
        display: none;
    }
    &--img{
        padding-top: 60%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        filter: grayscale(1);
        -webkit-filter: grayscale(1);
        transition: all .5s;
        position: relative;
        >a{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }
    }
    &--title{
        font-size : tR(18);
        line-height : tR(26);
        letter-spacing : 2.16px;
        margin-bottom: 10px;
        color: $black;
        font-weight: 700;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        a{
            color: $black;
            text-decoration: none;
            &:hover{
                color: $brown;
            }
        }
    }
    &--content{
        font-size : tR(12);
        line-height : tR(20);
        letter-spacing : 0.72px;
        color: $black;
        a{
            color: $black;
            text-decoration: none;
            &:hover{
                color: $brown;
            }
        }
    }
    &--my{
        font-family: 'Mukta Mahee', sans-serif !important;
        font-size: tR(16);
        color: $black;
        line-height: tR(16);
    }
    &--year{
        font-size: tR(16);
        line-height: tR(16);
    }
    &--date{
        font-family: 'Mukta Mahee';
        font-size: tR(40);
        line-height: tR(40);
        color: $black;
        font-weight: bold;
        margin: 2px 0;
    }
    &--desc {
        padding: 0px 20px;
    }
    &--link{
        font-family: 'Mukta Mahee';
        font-size: tR(12);
        color: $black;
        line-height: tR(12);
        &:hover{
            color: $red;
        }
    }
    &__grid{
        border-bottom: none;
        margin-bottom: 100px;
        &:hover{
            background: transparent;
            .newsItem--title{
                color: $brown;
            }
        }
        .newsItem--my, .newsItem--date{
            display: inline-block;
        }
        .newsItem--date{
            margin: 0 2px;
        }
        .newsItem--img{
            margin-bottom: 10px;
        }
        .newsItem--desc{
            padding-left: 0px;
            padding-right: 0px;
        }
        .newsItem--title{
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

//編輯器內容
.editorContent{
    img{
        margin-bottom: 30px;
    }
    p{
        font-size: tR(14);
        line-height : tR(24);
        letter-spacing : 1.68px;
        margin-bottom: 20px;
    }
}

//大圖元件
.bigVision{

}

//回到頂端
.to-top{
    cursor: pointer;
    position: fixed;
    bottom: 50px;
    right: 0px;
    width: 40px;
    height: 120px;
    padding-right: 20px;
    z-index: 999;
    img{
        max-width: 100%;
    }
}

//白色編筐
.w-border{
    border: 1px solid $white;
}

//Pagination
.pagination{
    .page-item{
        .page-link{
            background: none;
            color: $brown2;
            border: none;
            font-size: tR(12);
        }
        &:hover{
            .page-link{
                font-weight: bold;
            }
        }
    }
}

//Form
.select-box{
    position: relative;
    select{
        -webkit-appearance: none;
        background: none;
        font-size: tR(14);
        border: none;
        height: 40px;
        &:focus, &:active{
            -webkit-appearance: none;
            background: none;
            font-size: tR(14);
            border: none;
            box-shadow: none;
        }
    }
    >i{
        position: absolute;
        right: 15px;
        top: 8px;
        font-size: 20px;
        z-index: 3;
    }
}
